import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/workspace/inbox-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Upon first logging into the Inbox Health platform, you’ll find a collection of links on the left side of your screen we call the Inbox Health Navbar.  Clicking on each link in the Navbar will bring you to a summary or “index” page that gives you a detailed look into each of the important data models that power your healthcare business.  Clicking into each of the following links of your Navbar will let you create, update, and/or view each these record types:`}</p>
    <ul>
      <li parentName="ul">{`Patients`}</li>
      <li parentName="ul">{`Invoices (Charges)`}</li>
      <li parentName="ul">{`Payments`}</li>
      <li parentName="ul">{`Notes`}</li>
      <li parentName="ul">{`Appointments`}</li>
      <li parentName="ul">{`Gift Cards`}</li>
      <li parentName="ul">{`Reports`}</li>
      <li parentName="ul">{`Transfers`}</li>
      <li parentName="ul">{`Practice Settings`}</li>
    </ul>
    <h2 {...{
      "id": "patients"
    }}>{`Patients`}</h2>
    <p>{`TODO: Write an overview of Patient record management`}</p>
    <h3 {...{
      "id": "patients-index-overview"
    }}>{`Patients Index Overview`}</h3>
    <h3 {...{
      "id": "adding-a-patient"
    }}>{`Adding a patient`}</h3>
    <h3 {...{
      "id": "enter-and-update-patient-demographics"
    }}>{`Enter and update patient demographics`}</h3>
    <p>{`include importance of "Date of Birth field" to login), `}</p>
    <h2 {...{
      "id": "invoices"
    }}>{`Invoices`}</h2>
    <h3 {...{
      "id": "invoices-index"
    }}>{`Invoices Index`}</h3>
    <h3 {...{
      "id": "adding-a-new-invoice-charge"
    }}>{`Adding a new Invoice (Charge)`}</h3>
    <p>{`Walkthrough adding an Invoice from Patient Show `}</p>
    <h2 {...{
      "id": "payments"
    }}>{`Payments`}</h2>
    <h3 {...{
      "id": "processing-your-first-payment"
    }}>{`Processing your first Payment`}</h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      